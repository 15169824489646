import React, { useState, useEffect } from 'react';

import './style.scss';

const SearchPagination = ({ currentRefinement, nbPages, refine, padding, facets }) => {
  const pageItems = [];
  const [jumpToPage, setJumpToPage] = useState(currentRefinement);

  for (let i = 1; i <= nbPages; i++) {
    if (i === 1 || i === nbPages || Math.abs(i - currentRefinement) < padding) {
      pageItems.push(
        <button key={`page_${i}`}
          className={`SearchPagination__pageItem ${i === currentRefinement ? 'selected' : ''}`}
          onClick={(e) => {e.preventDefault(); if (i !== currentRefinement) refine(i);}}
        >
          {i}
        </button>
      );
    } else if (Math.abs(i - currentRefinement) === padding) { 
      pageItems.push(
        <span key={`page_${i}`} className="">
          ...
        </span>
      );
    }
  }

  const onSubmitPage = (e) => {
    e.preventDefault();

    if (jumpToPage !== currentRefinement) {
      refine(jumpToPage);
    }
  }

  useEffect(() => {
    refine(1);
    setJumpToPage(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facets])

  return (
    <div className="SearchPagination">
      <div className="SearchPagination__pageRange">
        <button className='Button small prev SearchPagination__button primary' 
          disabled={currentRefinement <= 1} 
          onClick={(e)=>{e.preventDefault(); refine(currentRefinement - 1);}}>
          <span className="label">Prev Page</span>
        </button>
        {pageItems}
        <button className='Button small next SearchPagination__button primary' 
          disabled={currentRefinement >= nbPages} 
          onClick={(e)=>{e.preventDefault(); refine(currentRefinement + 1)}}>
          <span className="label">Next Page</span>
        </button>
      </div>
      <div className="SearchPagination__pageJump">
        <span>Page: </span>
        <form onSubmit={onSubmitPage}>
          <input type="number"
            name="page"
            className="Input"
            value={jumpToPage}
            min="1"
            max={nbPages}
            onChange={({ target }) => setJumpToPage(parseInt(target.value))}
            required
          />
          <button className="Button small SearchPagination__button primary" type="submit">
            Go
          </button>
        </form>
      </div>
    </div>
  )
}

export default SearchPagination;
