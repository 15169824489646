import "../page-templates/ContentPage.scss";
import config from "../../gatsby-config.js";

import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Location, useLocation, navigate } from '@reach/router';
import styled from 'styled-components';
import MEDIA_QUERIES from 'src/consts/media-queries';

import NoHits from 'src/svg/nope.inline.svg';
import './styles/search_style.scss';

import queryString from 'query-string';
import algoliasearch from 'algoliasearch';
import { InstantSearch, Configure, connectStateResults, connectPagination, connectHighlight, connectHits } from 'react-instantsearch-dom';

import App from 'src/components/App';
import Menu from 'src/components/Sidebar/components/Menu';
import { Header } from 'src/components/Header';
import NewFooter from 'src/components/Footer';
import SearchPagination from './components/SearchPagination';
import MobileFacetFilterBox from 'src/pages/components/MobileFacetFilterBox';
import { PageTitle } from '../page-templates/styled-components/document-page-styled-components';

const lockFile = require('../../data/thorka-lock.json') || {};

const Wrapper = styled.div`
`;

const Container = styled.div`
  margin: 0 auto;
  padding: 0

  ${MEDIA_QUERIES.TABLET} {
    padding: 0;
  }

  ${MEDIA_QUERIES.MOBILE} {
    padding: 0;
  }
`;

const HighlightBase = ({ highlight, attribute, hit }) => {
  const parsedHit = highlight({
    highlightProperty: '_highlightResult',
    attribute,
    hit,
  });

  return (
    <span className="ais-Highlight HitContent">
      {parsedHit.map(
        (part, index) =>
          part.isHighlighted ? (
            <span className="ais-Highlight-highlighted" key={index}
              dangerouslySetInnerHTML={{__html: part.value}}
              />
          ) : (
            <span key={index}
              dangerouslySetInnerHTML={{__html: part.value}}
            />
          )
      )}
    </span>
  );
}

const HighlightTitle = ({ highlight, attribute, hit }) => {
  const parsedHit = highlight({
    highlightProperty: '_highlightResult',
    attribute,
    hit,
  });

  return (
    <span className="ais-Highlight HitTitle">
      {parsedHit.map(
        (part, index) =>
          part.isHighlighted ? (
            <span className="ais-Highlight-highlighted" key={index}
              dangerouslySetInnerHTML={{__html: part.value}}
              />
          ) : (
            <span key={index}
              dangerouslySetInnerHTML={{__html: part.value}}
            />
          )
      )}
    </span>
  );
}

const CustomHighlight = connectHighlight(HighlightBase);
const CustomHighlightTitle = connectHighlight(HighlightTitle);

export default () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          isCollection
          hasPrimaryNavigation
          primaryNavigation {
            path
            text
          }
        }
      }
    }
  `);

  const { 
    site: { siteMetadata: { isCollection, hasPrimaryNavigation, primaryNavigation: primaryNavData } }
  } = data;
  const loc = useLocation();
  let qs = loc.search ? queryString.parse(loc.search) : null;

  let { ALGOLIA_INDEX_NAME, ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY } = config.siteMetadata;

  const hidesVersion = null;
  const showSearch = false;
  const title = "Search";
  const orgName = "eosio";
  const currentVersion = "latest";
  const repoName = "";
  const mainNavData = (primaryNavData) ? primaryNavData : [{"text":"NONE","path":"EMPTY"}];
  const lockfile = Object.assign({}, lockFile);

  const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_SEARCH_KEY);

  const [searchConfig, setSearchConfig] = useState({
    query: qs ? qs.query : "",
    hitsPerPage: 10,
    attributesToRetrieve: ['*'],
    attributesToHighlight: ['*'],
    facetFilters: ['*'],
    snippetEllipsisText: '…',
    page: 1,
    distinct: true,
    analytics: true
  });
  const [filterState, setFilterState] = useState({
    versionList: [],
    selectedRepo: '*',
    selectedVersion: '',
    versionLabel: '',
    sectionLabel: 'Entire site'
  });

  const HitProp = ({ hit, page }) => {
    let url = `${hit.url_without_anchor}/?query=${searchConfig.query}&page=${page}${hit.anchor ? '#'+hit.anchor : ''}`;
    const tags = lockFile.entry_points[hit.path].tagged ? 
      lockFile.entry_points[hit.path].tags : [];
      
    let version = tags.length === 0 ?
      ''
    : tags.length === 1 ? tags[0].tag_reduced_friendly
    : hit.version ? tags.filter(function (tag) {
      if ((tag.latest && hit.version === 'latest') || tag.tag_reduced_friendly === hit.version) {
        return tag;
      }
      return null;
    })[0].tag_reduced_friendly
    : tags.filter(function (tag) {
      return tag.latest;
    })[0].tag_reduced_friendly;

    return <a href={url} className="HitContainer">
      <CustomHighlightTitle 
        hit={hit} 
        attribute={"hierarchy."+Object.keys(hit.hierarchy).filter(lvl => hit.hierarchy[lvl] !== null)[0]}
      />
      <h4 className="HitLocation">{lockFile.entry_points[hit.path].repo} {`${version !== '' ? '| '+version : ''}`}</h4>
      <h4 className="HitSubTitle" 
        dangerouslySetInnerHTML={
          { __html: hit.hierarchy[Object.keys(hit.hierarchy).reverse().filter(lvl => hit.hierarchy[lvl] !== null)[0]] }
        }
        />
      {
        hit.content 
          ? <div className="HitContentWrapper">
              <CustomHighlight hit={hit} attribute={"content"} />
            </div>
          : null
      }
    </a>
  }

  const Hits = ({ hits, page }) => {
    return (<ul>
      {hits.map((hit) => {
        return (      
          <li key={hit.objectID}>
            <HitProp hit={hit} page={page} />
          </li>
        )
      })}
    </ul>)
  }

  const CustomHits = connectHits(Hits);

  const Results = connectStateResults(
    ({ searchState, searchResults, children }) => {
      return searchResults && searchResults.nbHits !== 0 ? (
        <div>
          <CustomHits page={searchState.page} />
          {children}
        </div>
      ) : Object.keys(searchState).length > 0 || (Object.keys(searchState).length === 0 && (searchResults && searchResults.query)) ?
      (
        <div className="NoHits">
          <NoHits style={{marginTop: '-10px'}}/>
          <div className="NoHits__Header">No results found for query {`${qs ? '"'+qs.query+'".' : 'unknown.'}`}</div>
          <div className="NoHits__Text">
            Please try to search again or <button onClick={(e) => {e.preventDefault(); navigate(-1)}}>return to previous page</button>.
          </div>
        </div>
      ) : <h3 style={{textAlign:'center'}}>Searching...</h3>;
    }
  );

  const AlgoliaPagination = connectPagination(SearchPagination);

  useEffect(() => {
    setSearchConfig({
      ...searchConfig,
      query: loc.search ? queryString.parse(loc.search).query : ""
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loc.search]);

  return (
    <InstantSearch searchClient={searchClient} indexName={ALGOLIA_INDEX_NAME}>
      <App 
        {...{orgName, repoName, currentVersion, hidesVersion, showSearch, title }} >
        <div className="FullTemplate">
          <nav className="nav-col">
            <Location>
              {
                ({ location }) => {
                  return (
                    <div>
                      <Menu 
                        showMenuLinks={false} showFacetFilter={true} searchConfig={searchConfig}
                        configUpdateMethod={setSearchConfig} filterState={filterState}
                        filterUpdateMethod={setFilterState} lockFile={lockfile} location={location}
                        primaryNavData={mainNavData} isCollection={isCollection} 
                        currentQuery={searchConfig.query}
                        {...{repoName}} />
                    </div>
                  );
                }
              }
            </Location>
          </nav>
          <main>
            <Wrapper>
              <Container>
                  <Header
                    orgName={orgName}
                    repoName={repoName}
                    currentVersion={currentVersion}
                    showSearch={false}
                    hasPrimaryNavigation={hasPrimaryNavigation}
                    primaryNavData={mainNavData}
                    isTagged={true}
                    isCollection={isCollection}
                    pathKey={"/"}
                    showSearchState={true}
                    currentQuery={searchConfig.query}
                  />
                  <article className="ContentPage document-page-container">
                    <section>
                      <div className="search-page-content">
                        <Configure {...searchConfig} />
                        <div className="EnableMobileFacet">
                          <MobileFacetFilterBox searchConfig={searchConfig}
                            isCollection={isCollection}
                            configUpdateMethod={setSearchConfig} 
                            lockFile={lockfile}
                            filterState={filterState}
                            filterUpdateMethod={setFilterState}
                            />
                        </div>
                        <span style={{fontWeight: 600}}>Search result for</span>
                        <PageTitle title={searchConfig.query}>
                          <h1>{searchConfig.query}</h1>
                        </PageTitle>
                        <Results>
                          <AlgoliaPagination 
                            defaultRefinement={1}
                            facets={searchConfig.facetFilters}
                            padding={2}
                            />
                        </Results>
                      </div>
                    </section>
                  </article>
              </Container>
            </Wrapper>
          </main>
        </div>
        <footer>
          <NewFooter />
        </footer>
      </App>
    </InstantSearch>
  );
}
