const path = require('path');

const Ajv = require('ajv');
const ajv = new Ajv({ allErrors: true });

const lockFile = require('./data/thorka-lock.json');
const ThorkaLockSchema = require('./spec/thorka-lock.schema.json');
const pathPrefix = require('./data/path-prefix.json');
const indexDefault = require('./index.json');
const sitemapPrefix = (pathPrefix.pathPrefix === '/') ? "" : pathPrefix.pathPrefix;
const sitemapUrl = (process.env.SITE_URL) ? process.env.SITE_URL+sitemapPrefix+"/sitemap.xml"
  : "http://localhost:9000/sitemap.xml";

let isThorkaLockValid = ajv.validate(ThorkaLockSchema, lockFile);

if (!isThorkaLockValid) {
  console.error(ajv.errorsText(isThorkaLockValid.errors));
}

const config = {
    pathPrefix: '/',
    siteMetadata: {
      SITE_URL: process.env.SITE_URL || 'NONE',
      ALGOLIA_INDEX_NAME: process.env.ALGOLIA_INDEX_NAME || 'developers.eos.io-dev',
      ALGOLIA_APP_ID: process.env.ALGOLIA_APP_ID || '27W2JHT0ST',
      ALGOLIA_SEARCH_KEY: process.env.ALGOLIA_SEARCH_KEY || '25626fae796133dc1e734c6bcaaeac3c',
      siteUrl: process.env.SITE_URL || 'https://localhost:8080',
      isCollection: false,
    },
    plugins: [
        'gatsby-plugin-react-helmet',
        {
            resolve: 'gatsby-thorka-sitemap',
            options: {
              siteUrl: process.env.SITE_URL || 'http://localhost:9001',
              robotsTxtPolicy: {
                policy: [
                  {
                    userAgent: "*",
                    allow: "/",
                    crawlDelay: 2
                  }
                ],
                sitemap: sitemapUrl,
                host: process.env.SITE_URL || 'http://localhost:9001'
              },
              createGzip: false
            }
        },
        {
            resolve: 'gatsby-source-filesystem',
            options: {
                name: 'pages',
                path: `${__dirname}/src`
            }
        },
        {
            resolve: 'gatsby-source-filesystem',
            options: {
                name: 'data',
                path: `${__dirname}/data`,
                ignore: [`**/thorka-lock*`]
            }
        },
        'gatsby-transformer-sharp',
        'gatsby-plugin-styled-components',
        {
          resolve: 'gatsby-plugin-react-svg',
          options: {
            rule: {
              include: /\.inline\.svg$/
            }
          }
        },
        {
          resolve: `gatsby-transformer-json`,
          options: {
            typeName: ({ node, object, isArray }) => (object.openapi) ? "OpenApi" : "NotSwagger"
          }
        },
        {
            resolve: 'gatsby-transformer-remark',
            options: {
                commonmark: true,
                footnotes: true,
                pedantic: false,
                plugins: [
                    {
                      resolve: `gatsby-remark-autolink-headers`,
                      options: {
                        className: `anchor`,
                        icon: `<svg aria-hidden="true" focusable="false" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fill-rule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg>`,
                        maintainCase: false,
                        removeAccents: true,
                      },
                    },
                    'gatsby-remark-mermaid',
                    'gatsby-remark-draw',
                    `gatsby-remark-flowchart`, //See more details at http://flowchart.js.org/
                    {
                      resolve: `gatsby-remark-sequence`,
                      options: {
                        // see more details on https://github.com/bramp/js-sequence-diagrams
                        'theme': 'simple',
                      }
                    },
                    {
                      resolve: 'gatsby-remark-katex',
                      options: {
                        // Add any KaTeX options from https://github.com/KaTeX/KaTeX/blob/master/docs/options.md here
                        strict: 'ignore',
                        throwOnError: false
                      }
                    },
                    {
                      resolve: `gatsby-plugin-env-variables`,
                      options: {
                        whitelist: ["ALGOLIA_INDEX_NAME", "ALGOLIA_SEARCH_KEY", "ALGOLIA_APP_ID"]
                      },
                    },
/*                     {
                        resolve: 'gatsby-remark-images',
                        options: {
                            maxWidth: 780,
                            linkImagesToOriginal: false,
                            backgroundColor: 'none',
                        }
                    }, */
                    {
                      resolve: 'gatsby-remark-embed-video',
                      options: {
                        width: 800,
                        ratio: 1.77, // Optional: Defaults to 16/9 = 1.77
                        height: 400, // Optional: Overrides optional.ratio
                        related: false, //Optional: Will remove related videos from the end of an embedded YouTube video.
                        noIframeBorder: true //Optional: Disable insertion of <style> border: 0
                      }
                    },
                    'gatsby-remark-responsive-iframe',
                    'gatsby-remark-cookie-form',
                    'gatsby-remark-delete-cookie',
                    'gatsby-remark-deepurls',
                    'gatsby-remark-gitbook-ast-cleaner',
                    {
                      resolve: 'gatsby-remark-custom-blocks',
                      options: {
                        blocks: {
                          warning: {
                            classes: 'message warning',
                            title: 'optional',
                          },
                          caution: {
                            classes: 'message caution',
                            title: 'optional',
                          },
                          success: {
                            classes: 'message success',
                            title: 'optional',
                          },
                          info: {
                            classes: 'message info',
                            title: 'optional',
                          },
                          note: {
                            classes: 'message note',
                            title: 'optional',
                          }
                        },
                      },
                    },
                    {
                      resolve: 'gatsby-plugin-canonical-urls',
                      options: {
                        siteUrl: process.env.SITE_URL || 'https://docs.eos.io',
                      },
                    },
                    {
                        resolve: 'gatsby-remark-relative-links',
                        options: {
                            domainRegex: '/http[s]*:¥/¥/[www.]developers.eos.io[/]?/'
                        }
                    },
                    {
                        resolve: 'gatsby-remark-external-links'
                    },
                    {
                        resolve: 'gatsby-remark-prismjs',
                        options: {
                          aliases: {
                            'sh': 'bash',
                            'es6': 'javascript',
                            'js': 'javascript',
                            'env': 'bash',
                            'mdx': 'md',
                            '.json': 'json',
                            'gitignore': `none`,
                            'gql': 'graphql',
                            'c++': 'cpp',
                            'C++': 'cpp',
                            'C#': 'csharp',
                          },
                          showLineNumbers: false,
                          prompt: {
                            global: false,
                            user: "user"
                          }
                        }
                    },
                    {
                      resolve: `gatsby-remark-copy-linked-files`,
                      options: {
                        ignoreFileExtensions: [],
                      }
                    },
                    {
                      resolve: 'gatsby-remark-deepurls-checklink',
                      options: {
                        slient: false,
                        endsExceptions: [
                          "CONTRIBUTING",
                          "LICENSE",
                          "IMPORTANT",
                          "json",
                          "html",
                        ]
                      }
                    }
                  ]
            }
        },
        {
            resolve: 'gatsby-plugin-sass',
            options: {
              includePaths: ['src/scss']
            },
        },
        'gatsby-plugin-catch-links',
        'gatsby-plugin-sharp',
        {
            resolve: 'gatsby-plugin-root-import',
            options: {
              src: path.join(__dirname, 'src'),
              data: path.join(__dirname, 'data')
            }
        },
    ]
}

if(pathPrefix.pathPrefix) config.pathPrefix = pathPrefix.pathPrefix




// Add hotjar if the id and snippet version exists
const hotjarId = process.env.HOTJAR_ID;
const hotjarSV = process.env.HOTJAR_SNIPPET_VERSION;
if (hotjarId && hotjarSV) {
  config.plugins.push({
    resolve: 'gatsby-plugin-hotjar',
    options: {
      id: parseInt(hotjarId),
      sv: parseInt(hotjarSV)
    }
  });
} else {
  console.log('Skip adding hotjar as its id and snippet version do not exist')
}

const gtmId = process.env.GTM_ID;
if (gtmId) {
  config.plugins.push({
    resolve: 'gatsby-plugin-google-tagmanager',
    options: {
      id: gtmId,
      // Include GTM in development.
      // Defaults to false meaning GTM will only be loaded in production.
      includeInDevelopment: true,
      defaultDataLayer: {
        organization: '',
        repository: '',
        version: '',
      },
      dataLayerName: `dataLayer`
    },
  });
} else {
  console.log('Skip adding GTM as its id do not exist')
}

const paths = Object.keys(lockFile['entry_points']);

const getSummaryPath = (isSummary, key = '', tag = '') => {
  let summaryPath = '';
  let summaryKeyIsBoolean = (typeof isSummary === 'boolean');

  if (isSummary && summaryKeyIsBoolean) {
    summaryPath = (tag !== '') ?
      path.join(__dirname, 'data', key, tag, 'SUMMARY.json')
    : path.join(__dirname, 'data', key, 'SUMMARY.json');
  } else if (isSummary && !summaryKeyIsBoolean) {
    // Fallback: If the tag is not latest, use the version tag's SUMMARY.json instead
    // This may be due to SUMMARY.json not matching properly.
    summaryPath = (tag === 'latest') ?
      path.join(__dirname, 'data', isSummary, 'SUMMARY.json')
    : path.join(__dirname, 'data', key, tag, 'SUMMARY.json');
  }

  return summaryPath;
}

const boolToInt = (bool) => bool ? 1 : 0;

const parseLockFile = (mainTree, pathKey = '/') => {
  let title = mainTree.org+'/'+mainTree.repo;
  let rootSummaryPath = getSummaryPath(mainTree.summary, pathKey);
  let tagsDropdown = [];

  if (mainTree.tagged) {
    // Tagged
    rootSummaryPath = '';
    tagsDropdown = mainTree.tags.map(tag => {
      let item = tag;
      let version = (tag.latest) ? 'latest' : tag.tag_reduced_friendly;
      let summaryKeyIsBoolean = typeof mainTree.summary === 'boolean';
      /***
       * Gatsby will build all sites in a single run...
       * The tool originally relies on a single SUMMARY.json
       * But now we must change it so the SUMMARY.json used is correct.
       * Case 1: Untagged repo - just use SUMMARY.json at root if TRUE
       * Case 2: Tagged repo - iterate thru all version folders for SUMMARY.json if TRUE
       * Case 3: Collection - Evaluate based on case 1 and case 2
       * Case 4: Collection - Drill-down to get the correct path of SUMMARY.json if present.
       */
      item.rc = (typeof item.rc === 'boolean') ? boolToInt(item.rc) : parseInt(item.rc);
      item.beta = (typeof item.rc === 'boolean') ? boolToInt(item.beta) : parseInt(item.beta);
      item.build = (typeof item.rc === 'boolean') ? boolToInt(item.build) : parseInt(item.build);
      item.summaryPath = getSummaryPath(mainTree.summary, pathKey, version);
      item.importedSummary = (summaryKeyIsBoolean || (!summaryKeyIsBoolean && version !== 'latest')) ? 'N/A' : mainTree.summary;
      item.version = version;
      return item;
    });
  } else {
    let tag = {
      'latest': true,
      'tag': '1.0.0',
      'tag_reduced': '1.0',
      'tag_sanitized': '1.0',
      'tag_reduced_friendly': 'v1.0',
      'rc': 0,
      'beta': 0,
      'build': 0,
      'deprecated': false,
      'version': 'latest',
      'summaryPath': path.join(__dirname, 'data', pathKey, 'SUMMARY.json'),
      'importedSummary': (typeof mainTree.summary === 'boolean') ? 'N/A' : mainTree.summary
    };
    tagsDropdown.push(tag);
  }

  return {
    key: pathKey,
    title: title,
    tagged: mainTree.tagged,
    tagsDropdown: tagsDropdown,
    search: mainTree.search,
    hasSummary: !!mainTree.summary,
    org: mainTree.org,
    repo: mainTree.repo,
    root: mainTree.root,
    summaryPath: rootSummaryPath
  };
}

if (paths.length > 1) {
  /**
   * Collection type
   * Integrate data from the lockfile into the site's metadata
   * Make it accessible from a global context
   * 1. Iterate through objects using the specified path as a key
   * 2. Untagged sites have an empty tagsDropdown
   */
  let siteData = (lockFile['site']) ? lockFile['site'] : null;
  let deconstructedData = paths.map(path => parseLockFile(lockFile['entry_points'][path], path));
  let primaryNav = (siteData && siteData['primary_navigation']) ? siteData['primary_navigation'] : [{'text':'NONE','path':'EMPTY'}];
  let landingPage = (siteData && siteData['landing_page']) ? siteData['landing_page'] : indexDefault;
  let redirects = (siteData && siteData['redirects']) ? siteData['redirects'] : [{'original': 'NONE', 'new': '/'}];
  let primaryNavExists = (primaryNav.length > 0 && primaryNav[0].path !== 'EMPTY') ? true : false;
  let redirectsExists = (redirects.length > 0 && redirects[0].original !== 'NONE') ? true : false;

  config.siteMetadata.sitemap = deconstructedData;
  config.siteMetadata.isCollection = true;
  config.siteMetadata.primaryNavigation = primaryNav;
  config.siteMetadata.hasPrimaryNavigation = primaryNavExists;
  config.siteMetadata.landingPage = landingPage;
  config.siteMetadata.redirects = redirects;
  config.siteMetadata.redirectsExists = redirectsExists;
  config.siteMetadata.listOfRootPaths = paths;

} else {
  /**
   * Tagged or untagged type
   * Use a function 'parseLockFile' to parse the appropriate sitemap data
   * It will check automatically if it is tagged or not by checking the
   * tagged key
   */
  let siteData = (lockFile['site']) ? lockFile['site'] : null;
  let deconstructedData = parseLockFile(lockFile['entry_points']['/']);
  let primaryNav = (siteData && siteData['primary_navigation']) ? siteData['primary_navigation'] : [{'text': 'NONE','path':'EMPTY'}];
  let landingPage = (siteData && siteData['landing_page']) ? siteData['landing_page'] : indexDefault;
  let redirects = (siteData && siteData['redirects']) ? siteData['redirects'] : [{'original': 'NONE', 'new': '/'}];
  let primaryNavExists = (primaryNav.length > 0 && primaryNav[0].path !== 'EMPTY') ? true : false;
  let redirectsExists = (redirects.length > 0 && redirects[0].original !== 'NONE') ? true : false;

  config.siteMetadata.sitemap = [deconstructedData];
  config.siteMetadata.isCollection = false;
  config.siteMetadata.primaryNavigation = primaryNav;
  config.siteMetadata.landingPage = landingPage;
  config.siteMetadata.hasPrimaryNavigation = primaryNavExists;
  config.siteMetadata.redirects = redirects;
  config.siteMetadata.redirectsExists = redirectsExists;
  config.siteMetadata.listOfRootPaths = paths;

}

module.exports = config
